import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { MicroProfitFormModel } from "../client-forms.models";
import { ClientFormsService } from "../client-forms.service";
import { AuthService, PredefinedUserZone } from "@saliente/common-auth";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { switchMap } from "rxjs/operators";
import { IntlService } from "@progress/kendo-angular-intl";
import { Align } from "@progress/kendo-angular-popup";

@Component({
	selector: 'mp2024-2025-m2p-form',
	templateUrl: 'mp2024-2025-m2p-form.component.html',
    styleUrls: ['mp2024-2025-m2p-form.component.scss']

})
export class MP20242025M2PFormComponent implements OnInit, OnDestroy {
	@Input()
    public isDialog: boolean;
    @Input()
    public clientEidParam: string;
    @Input()
    public instanceParam: string;
    @Input()
    public is202503FormParam: boolean;

    @Output() public close = new EventEmitter();

    //subscriptions
	private dataSubscription: Subscription;
    
    //models
    public formModel: MicroProfitFormModel;

    public clientEid: string;
    public instance: string;
    public is202503Form: boolean;

    //booleans
    public isReadOnly: boolean;
    public hasViewRight: boolean;
    public isLoading: boolean;
    public showConfirmationDialog: boolean = false;
    public showCAENQuestion: boolean = false;
    public caenCodesPopupVisible: boolean = false;

    //UI
	public caenCodesPopupAlign: Align = { horizontal: "right", vertical: "bottom" };
	public caedCodesAnchorAlign: Align = { horizontal: "center", vertical: "top" };
	@ViewChild("caenCodesAnchor") public caenCodesAnchor: ElementRef;
	@ViewChild("caenCodesPopup", { read: ElementRef }) public caenCodesPopup: ElementRef;
    
    get are1FTEDisabled(): boolean {
        return false;
    }
    
    get caSub500kDisabled(): boolean {
        return this.is202503Form 
            ? (this.formModel.data.are1FTE == null || !this.formModel.data.are1FTE)
            : false
    }

    get are253MicroDisabled(): boolean {
        return this.formModel.data.caSub500k == null || !this.formModel.data.caSub500k;
    }

    get pastreazaFirmaMicroDisabled(): boolean {
        return this.formModel.data.are253Micro == null || !this.formModel.data.are253Micro;
    }

	get saveDisabled(): boolean {
        return !this.isConclusionSet;
    }

    get isConclusionSet() : boolean {
        return ((this.formModel.data.ramaneMicro || this.formModel.data.devineMicro) && (this.formModel.data.micro3p || this.formModel.data.micro1p))
            || this.formModel.data.devineProfit 
            || this.formModel.data.ramaneProfit;
    }

	constructor(private clientFormsService: ClientFormsService, private authService: AuthService, private route: ActivatedRoute, private toastr: ToastrService,
        private cdr: ChangeDetectorRef, private intl: IntlService, private activatedRoute: ActivatedRoute) {}
        
    ngOnInit(): void {
        this.isLoading = true;
        this.dataSubscription = this.route.paramMap
            .pipe(
                switchMap((params: ParamMap) => {
                    this.clientEid = this.isDialog ? this.clientEidParam : params.get('id');
                    this.is202503Form = this.isDialog ? this.is202503FormParam : this.activatedRoute.snapshot.data.is202503Form;
                    this.instance = this.is202503Form ? "mp2024-2025-03-m2p" : "mp2024-2025-m2p";

                    return this.clientFormsService.getMicroProfitData(this.clientEid, this.instance);
                })
		    )
			.subscribe(async (formData: MicroProfitFormModel) => {
                if (formData) {
                    this.isReadOnly = this.authService.selectedZoneCode == PredefinedUserZone.Expert || formData.formData.status == 'Submitted';

                    this.hasViewRight = this.authService.isInRole('EXPERT') || this.authService.isInRole('ZEUS') 
                        || this.authService.isInRole(formData.formData.displayRole);

                    if (this.hasViewRight) {
                        this.formModel = formData;

                        if (this.is202503Form) {
                            let profitMicroCode = await this.clientFormsService.getClientFiscalInfoSummaryForMonth(this.clientEid, formData.formDataParameters.luna);
                            if (profitMicroCode == 'Micro3' && !this.isReadOnly) {
                                this.showCAENQuestion = false;
                                this.formModel.data.hasCaenCode = true;
                            }
                            else {
                                this.showCAENQuestion = true;
                                this.formModel.data.hasCaenCode = false;
                            }
                        
                            if (this.formModel.data.micro1p != null && this.formModel.data.micro1p) {
                                this.formModel.data.hasCaenCode = false;
                            }

                            if (this.formModel.data.micro3p != null && this.formModel.data.micro3p) {
                                this.formModel.data.hasCaenCode = true;
                            }
                        }

                        this.initializeConclusion();

                        if (this.formModel.formData.status != 'Submitted') {
                            this.computeBooleans();
                        }

                        this.getFormConclusion();
                    }
                }
                else {
                    this.formModel = null;
                }

                this.isLoading = false;

                setTimeout(() => {
                    this.cdr.detectChanges();
                }, 200);
		});
    }

    ngOnDestroy(): void {
        if (this.dataSubscription) { this.dataSubscription.unsubscribe(); this.dataSubscription = null; }
    }

    public onAre1FTEHelp() {
        let url = "https://app.keez.ro/help/client/faq/modificari_fiscale_2023.html#conditie-micro-angajat";
		window.open(url, '_blank', 'noopener noreferrer');
    }

	public onCaSub500k() {
        let url = "https://app.keez.ro/help/client/faq/modificari_fiscale_2024.html#intreprinderi-legate";
		window.open(url, '_blank', 'noopener noreferrer');
    }

    public onAre253Micro() {
        let url = "https://app.keez.ro/help/client/faq/modificari_fiscale_2023.html#conditie-micro-actionari-cu-25-din-partile-sociale-la-3-firme";
		window.open(url, '_blank', 'noopener noreferrer');
    }

	public onRamaneMicroHelp() {
        let url = this.formModel.data.ramaneMicro 
            ? "https://app.keez.ro/help/client/antreprenor/taxe_impozite.html#impozitul-pe-venit"
            : "https://app.keez.ro/help/client/antreprenor/taxe_impozite.html#impozitul-pe-profit";

        window.open(url, '_blank', 'noopener noreferrer');   
    }

    public showCaenCodes() {
		this.caenCodesPopupVisible = !this.caenCodesPopupVisible;
    }

    public computeBooleans() {
        this.onAre1FTEChange();
		this.onCaSub500kChange();
        this.onAre253MicroChange();
        this.onPastreazaFirmaMicroChange();
	}

	public initializeConclusion() {
		this.formModel.data.ramaneMicro = this.formModel.data.ramaneMicro == null ? false : this.formModel.data.ramaneMicro;
        this.formModel.data.devineProfit = this.formModel.data.devineProfit == null ? false : this.formModel.data.devineProfit;
        this.formModel.data.devineMicro = this.formModel.data.devineMicro == null ? false : this.formModel.data.devineMicro;
        this.formModel.data.ramaneProfit = this.formModel.data.ramaneProfit == null ? false : this.formModel.data.ramaneProfit;

        if (this.is202503Form) {
            if (!this.showCAENQuestion && (this.formModel.data.ramaneMicro || this.formModel.data.devineMicro)) {
                this.formModel.data.micro3p = true;
                this.formModel.data.micro1p = false;
            }
        }
    }

    public noConclusion() {
        this.formModel.data.ramaneMicro = false;
        this.formModel.data.devineProfit = false;
        this.formModel.data.devineMicro = false;
        this.formModel.data.ramaneProfit = false;
    }
    
    public ramaneMicro() {
        this.formModel.data.ramaneMicro = true;
        this.formModel.data.devineProfit = false;
        this.formModel.data.devineMicro = false;
        this.formModel.data.ramaneProfit = false;
    }

    public devineMicro() {
        this.formModel.data.ramaneMicro = false;
        this.formModel.data.devineProfit = false;
        this.formModel.data.devineMicro = true;
        this.formModel.data.ramaneProfit = false;
    }

    public ramaneProfit() {
        this.formModel.data.ramaneMicro = false;
        this.formModel.data.devineProfit = false;
        this.formModel.data.devineMicro = false;
        this.formModel.data.ramaneProfit = true;
    }

	public devineProfit() {
        this.formModel.data.ramaneMicro = false;
        this.formModel.data.devineProfit = true;
        this.formModel.data.devineMicro = false;
        this.formModel.data.ramaneProfit = false;
    }

	public getFormConclusion() {
		if (this.is202503Form) {
            if (this.formModel.data.ramaneMicro) {
                if (!this.showCAENQuestion)
                    return 'Firma va rămâne Microîntreprindere - plătitoare de impozit pe venit 3%.';
                return 'Firma va rămâne Microîntreprindere - plătitoare de impozit pe venit.';
            }
            else if (this.formModel.data.devineMicro) {
                return 'Firma va deveni Microîntreprindere - plătitoare de impozit pe venit.'
            }
            else if (this.formModel.data.ramaneProfit) {
                return 'Firma va ramane plătitoare de impozit pe profit.';
            }
            else if (this.formModel.data.devineProfit) {
                return 'Firma va deveni plătitoare de impozit pe profit.';
            }
            else {
                return null;
            }
		}
		else {
			if (this.formModel.data.ramaneMicro) {
				return 'Firma va rămâne Microîntreprindere - plătitoare de impozit pe venit.'; // 1%
			}
			else if (this.formModel.data.devineProfit) {
				return `Firma va deveni plătitoare de impozit pe profit începând cu luna ${this.intl.formatDate(this.formModel.formDataParameters.firstDayOfNextMonth, "MMMM yyyy")}.`;
			}
			else {
				return null;
			}
		}
    }

    public getProcentVenitConclusion() {
        if (this.formModel.data.micro1p) {
            return 'Microîntreprindere - plătitoare de impozit pe venit 1%.';
        }
        else if (this.formModel.data.micro3p) {
            return 'Microîntreprindere - plătitoare de impozit pe venit 3%.'
        }
        else {
            return null;
        }
    }

    public resetAnswersFromQuestionBelow(fromQuestionBelow: string) {
        if (fromQuestionBelow == "Are1FTE") {
            this.formModel.data.caSub500k = null;
            this.formModel.data.are253Micro = null;
            this.formModel.data.pastreazaFirmaMicro = null;
            this.formModel.data.hasCaenCode = null;
            this.formModel.data.micro1p = null;
            this.formModel.data.micro3p = null;
        }
        if (fromQuestionBelow == "CaSub500k") {
            this.formModel.data.are253Micro = null;
            this.formModel.data.pastreazaFirmaMicro = null;
            this.formModel.data.hasCaenCode = null;
            this.formModel.data.micro1p = null;
            this.formModel.data.micro3p = null;
        }
        if (fromQuestionBelow == "Are253Micro") {
            this.formModel.data.pastreazaFirmaMicro = null;
            this.formModel.data.hasCaenCode = null;
            this.formModel.data.micro1p = null;
            this.formModel.data.micro3p = null;
        }
        if (fromQuestionBelow == "PastreazaFirmaMicro") {
            this.formModel.data.hasCaenCode = null;
            this.formModel.data.micro1p = null;
            this.formModel.data.micro3p = null;
        }
    }

    public onAre1FTEChange() {
        if (!this.is202503Form) {
            return;
        }

        if (this.formModel.data.are1FTE == null) 
            return;

        if (this.formModel.data.are1FTE) { // answer: yes - no conclusion yet, need to answer the following questions
           this.noConclusion();
        }
        else { // answer: no - there is no need for the other questions: devineProfit
            this.devineProfit();
            this.resetAnswersFromQuestionBelow("Are1FTE");
        }
    }

	public onCaSub500kChange() { 
		if (this.is202503Form) {
            if (this.formModel.data.caSub500k == null) 
                return;

            if (this.formModel.data.caSub500k) { // answer: yes - no conclusion yet, need to answer the following questions
                this.noConclusion();
            }
            else { // answer: no - there is no need for the following questions: devineProfit
                this.devineProfit();
                this.resetAnswersFromQuestionBelow("CaSub500k");
            }

		}  
		else {
			if (this.formModel.data.caSub500k) { // answer: yes - there is no need for the following questions: ramaneMicro 1%
				this.ramaneMicro(); 
                this.formModel.data.micro1p = true;
                this.formModel.data.micro3p = false;
			}
			else {
				this.devineProfit(); // answer: no - there is no need for the following questions: ramaneProfit
                this.formModel.data.micro1p = false;
                this.formModel.data.micro3p = false;
			}
		}
    }

    public onAre253MicroChange() {
        if (!this.is202503Form) {
            return;
        }

        if (this.formModel.data.are253Micro == null) 
            return;

        if (this.formModel.data.are253Micro) { // answer: yes - no conclusion yet, need to answer the following questions
            this.noConclusion();
            if (this.formModel.data.pastreazaFirmaMicro) { // if there was any prev default
                this.onPastreazaFirmaMicroChange();
            }
        }
        else { // answer: no - there is no need for the following questions: ramaneMicro
            this.ramaneMicro();
            this.resetAnswersFromQuestionBelow("Are253Micro");

            this.formModel.data.pastreazaFirmaMicro = true;

            if (!this.showCAENQuestion) {
                this.formModel.data.micro3p = true;
                this.formModel.data.micro1p = false;
            }
        }
    }

    public onPastreazaFirmaMicroChange() {
        if (!this.is202503Form) {
            return;
        }

        if (this.formModel.data.pastreazaFirmaMicro == null) 
            return;

        if (this.formModel.data.pastreazaFirmaMicro) { // answer: yes - ramaneMicro
            this.ramaneMicro();

            if (!this.showCAENQuestion) {
                this.formModel.data.micro3p = true;
                this.formModel.data.micro1p = false;

                this.formModel.data.pastreazaFirmaMicro = true;
            }
        }
        else { // answer: no - devineProfit
            this.devineProfit();
            this.resetAnswersFromQuestionBelow("PastreazaFirmaMicro");
        }
    }

    public onAreCodCaenChange() {
        if (this.formModel.data.hasCaenCode) {
            this.formModel.data.micro3p = true;
            this.formModel.data.micro1p = false;
        }
        else {
            this.formModel.data.micro1p = true;
            this.formModel.data.micro3p = false;
        }
    }

	public openConfirmationDialog() {
        this.showConfirmationDialog = true;
    }

    public closeConfirmationDialog() {
        this.showConfirmationDialog = false;
    }

    public async saveMicroProfitForm() {
        let result = await this.clientFormsService.updateClientFormMicroProfitData(this.clientEid, this.instance, this.formModel.data);
        if (result) {
            if (!this.isDialog) {
                this.formModel.formData.status = "Submitted";
                this.isReadOnly = this.authService.selectedZoneCode == PredefinedUserZone.Expert || this.formModel.formData.status == 'Submitted';
            }
            
            this.cancelMicroProfitForm();
            this.toastr.success("Formularul a fost salvat cu succes!");
        }
    }

    public cancelMicroProfitForm() {
        this.closeConfirmationDialog();

        if (this.isDialog) {
            this.close.emit();
        }
    }
}